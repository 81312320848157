// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { API_URL } from '@configs/endpoint'
// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async (col, type) => {
  const response = await axios.get(`${API_URL}/users?col=${col}&type=${type}`)
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`${API_URL}/users`, params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${API_URL}/users`, { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post(`${API_URL}/users`, user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData(user.col))
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(`${API_URL}/users`, { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    col: 'users',
    data: [],
    meta: {
      total: 0, verfied: 0
    },
    total: 0,
    params: {},
    allData: [],
    isLoading: true,
    page: 1,
    selectedUser: null,
    perPage : 10
  },
  reducers: {
    onPageChage: (state, action) => {
      state.page = action.payload
      state.data = state.allData.slice(action.payload * state.perPage - state.perPage, action.payload * state.perPage)
    },
    onPerPageChange: (state, action) => {
      state.perPage = action.payload
      state.data = state.allData.slice(0, action.payload)
      state.page = 1
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        
        state.isLoading= false
        state.allData = action.payload.users
        state.total = action.payload.users.length
        state.data = action.payload.users.slice(0, 10)
        state.meta = action.payload.meta
      })
      .addCase(getData.fulfilled, (state, action) => {
        //state.data = action.payload.data
        //state.params = action.payload.params
        //state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export const {
  onPageChage, onPerPageChange
} = appUsersSlice.actions

export default appUsersSlice.reducer