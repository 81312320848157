// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import post from "./post"
import posts from "./posts"
import emails from "./emails"
import media from "./media"
import tags from "./tags"
import users from "./users"
import kanban from "./kanban"
import contacts from "./contacts"
import jobs from "./jobs"
import job from "./job"

import authentication from "./authentication"
const rootReducer = { navbar, layout, 
    post, posts, emails, media, tags,
    users, kanban, contacts,
    authentication,
    jobs, job
}

export default rootReducer
