// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"

const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
  }

var user = initialUser()
const initialEmail = user.role === "admin" ? "faizan@mlhive.com" : user.email


export const emails = createSlice({
    name: "emails",
    initialState: {
        mails: [],
        selectedEmailAccount: initialEmail,
        emailAccounts: [
            initialEmail
        ],
        allData: [],
        selectedMails: [],
        emailsMeta: {},
        isLoading: false,
        isError: false,
        total: 0,
        currentPage: 1,
        rowsPerPage: 10,
        searchText: "",
        currentMail : null
    },
    reducers: {
        startFetchEmails: (state) => {
            state.isLoading = true
            state.isError = false
        },
        successFetchEmails: (state, action) => {
            state.mails = action.payload.emails.slice(0, state.rowsPerPage)
            state.mails = action.payload.emails
            state.total = action.payload.emails.length
            state.emailsMeta = action.payload.meta[state.selectedEmailAccount.split("@")[0]]
            state.isLoading = false
            state.emailAccounts = Object.keys(action.payload.meta).map((key) => key + "@mlhive.com")
        },
        changePage: (state, action) => {
            state.currentPage = action.payload
            state.mails = state.allData.slice(action.payload * state.rowsPerPage - state.rowsPerPage, action.payload * state.rowsPerPage)
        },
        changeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
            state.data = state.allData.slice(0, action.payload)
            state.currentPage = 1
        },
        changeSearchTerm: (state, action) => {
            state.searchText = action.payload
            // if (action.payload !== ""){
            //     state.data = state.allData.filter(post => post.title.toLowerCase().includes(action.payload))
            //     state.currentPage = 1
            // }else{
            //     state.data = state.allData.slice(0, state.rowsPerPage)
            //     state.currentPage = 1
            // }
        },
        selectCurrentMail : (state, action) => {
            state.currentMail = state.mails.find(mail => mail.id === action.payload)
        },
        selectMail : (state, action) => {
            if (state.selectedMails.includes(action.payload)){
                // remove mail from selectedMails
                state.selectedMails = state.selectedMails.filter(mail => mail !== action.payload)
            }else{
                state.selectedMails.push(action.payload)
            }
        },
        resetSelectedMail : (state) => {
            state.selectedMails = []
        },
        setSelectedEmailAccount : (state, action) => {
            state.selectedEmailAccount = action.payload
        }
        
    }
})

export const {
    startFetchEmails, successFetchEmails, changePage, changeRowsPerPage,
    changeSearchTerm, selectCurrentMail, selectMail, resetSelectedMail,
    setSelectedEmailAccount
} = emails.actions

export default emails.reducer
