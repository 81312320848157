// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
// ** ThemeConfig Import
import { API_URL } from '@configs/endpoint'

export const getContacts = createAsyncThunk("contacts/getContacts", async () => {
    const response = await axios.get(`${API_URL}/contacts`)
    const data = await response.data.contacts
    console.log(data)
    return data
})

export const deleteContact = createAsyncThunk("contacts/deleteContact", async (id) => {
    const response = await axios.delete(`${API_URL}/contacts`, { data: { id } })
    const data = await response.data

    // Delete from store
    return { id }

})

export const deleteContacts = createAsyncThunk("contacts/deleteContacts", async (ids) => {
    const response = await axios.delete(`${API_URL}/contacts`, { data: { ids } })
    const data = await response.data

    // Delete from store
    return { ids }

})

export const setSpam = createAsyncThunk("contacts/setSpam", async (id) => {
    const response = await axios.post(`${API_URL}/contacts/spam`, { id })
    const data = await response.data

    // Delete from store
    return { id }
})

export const contacts = createSlice({
    name: "contacts",
    initialState: {
        data: [],
        allData: [],
        selectedTasks: [],
        selectedTag: {},
        isLoading: true,
        isError: false,
        total: 0,
        currentPage: 1,
        rowsPerPage: 10,
        searchText: "",
    },
    reducers: {
        startFetchContacts: (state) => {
            state.isLoading = true
            state.isError = false
        },
        successFetchContacts: (state, action) => {
            state.data = action.payload.slice(0, state.rowsPerPage)
            state.allData = action.payload
            state.total = action.payload.length
            state.isLoading = false
        },
        changePage: (state, action) => {
            state.currentPage = action.payload
            state.data = state.allData.slice(action.payload * state.rowsPerPage - state.rowsPerPage, action.payload * state.rowsPerPage)
        },
        changeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
            state.data = state.allData.slice(0, action.payload)
            state.currentPage = 1
        },
        changeSearchTerm: (state, action) => {
            state.searchText = action.payload
        },
        setSelectedContact: (state, action) => {
            state.selectedTag = action.payload
        },
        filterContacts: (state, action) => {
            state.searchText = action.payload
            state.data = state.allData.filter(tag => tag.name.toLowerCase().includes(action.payload.toLowerCase()))
        },
        checkTask: (state, action) => {
            if (state.selectedTasks.includes(action.payload)) {
                state.selectedTasks = state.selectedTasks.filter(task => task !== action.payload)
            }
            else {
                state.selectedTasks = [...state.selectedTasks, action.payload]
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getContacts.fulfilled, (state, action) => {
            console.log("fetch success")
            state.data = action.payload
            state.allData = action.payload
            state.total = action.payload.length
            state.isLoading = false
        }),
        builder.addCase(deleteContact.fulfilled, (state, action) => {
            state.data = state.data.filter(contact => contact.id !== action.payload.id)
            state.allData = state.allData.filter(contact => contact.id !== action.payload.id)
            state.selectedTag = {}
            state.total = state.data.length
        }),
        builder.addCase(deleteContacts.fulfilled, (state, action) => {
            state.data = state.data.filter(contact => !action.payload.ids.includes(contact.id))
            state.allData = state.allData.filter(contact => !action.payload.ids.includes(contact.id))
            state.selectedTag = {}
            state.total = state.data.length
            state.selectedTasks = []
        }),
        builder.addCase(setSpam.fulfilled, (state, action) => {
            state.data = state.data.filter(contact => contact.id !== action.payload.id)
            state.allData = state.allData.filter(contact => contact.id !== action.payload.id)
            state.selectedTag = {}
            state.total = state.data.length
        }
        )
    }
})

export const {
    startFetchContacts, successFetchContacts, changePage, changeRowsPerPage,
    setSelectedContact, filterContacts,
    changeSearchTerm,
    checkTask
} = contacts.actions

export default contacts.reducer
