// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"



export const posts = createSlice({
    name: "posts",
    initialState: {
        data: [],
        allData: [],
        isLoading: false,
        isError: false,
        total: 0,
        currentPage: 1,
        rowsPerPage: 10,
        searchText: "",
    },
    reducers: {
        startFetchPosts: (state) => {
            state.isLoading = true
            state.isError = false
        },
        successFetchPosts: (state, action) => {
            state.data = action.payload.slice(0, state.rowsPerPage)
            state.allData = action.payload
            state.total = action.payload.length
            state.isLoading = false
        },
        changePage: (state, action) => {
            state.currentPage = action.payload
            state.data = state.allData.slice(action.payload * state.rowsPerPage - state.rowsPerPage, action.payload * state.rowsPerPage)
        },
        changeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
            state.data = state.allData.slice(0, action.payload)
            state.currentPage = 1
        },
        changeSearchTerm: (state, action) => {
            state.searchText = action.payload
            if (action.payload !== ""){
                state.data = state.allData.filter(post => post.title.toLowerCase().includes(action.payload))
                state.currentPage = 1
            }else{
                state.data = state.allData.slice(0, state.rowsPerPage)
                state.currentPage = 1
            }
        }
    }
})

export const {
    startFetchPosts, successFetchPosts, changePage, changeRowsPerPage,
    changeSearchTerm
} = posts.actions

export default posts.reducer
