// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"



export const media = createSlice({
    name: "media",
    initialState: {
        data: {
            images: {
                post: [],
                thumbnails: [],
                web: []
            }

        },
        selectedUrls: [],
        isLoading: false,
        isError: false,
        total: 0,
        currentPage: 1,
        rowsPerPage: 20,
        searchText: "",
    },
    reducers: {
        startFetchMedia: (state) => {
            state.isLoading = true
            state.isError = false
        },
        successFetchMedia: (state, action) => {
            state.data = action.payload
            state.total = action.payload.length
            state.isLoading = false
        },
        onSelectMedia: (state, action) => {
            // set selected to true to index
            if (state.selectedUrls.filter(img => img.url === action.payload.url).length !== 0) {
                state.selectedUrls = state.selectedUrls.filter(img => img.url !== action.payload.url)
            } else {
                console.log("FOUND")
                state.selectedUrls.push(action.payload)
            }
        },
        onClearSelectedMedia: (state) => {
            state.selectedUrls = []
        }
    }
})

export const {
    startFetchMedia, successFetchMedia, onSelectMedia, onClearSelectedMedia
} = media.actions

export default media.reducer
