// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '@configs/endpoint'
// ** Axios Imports
import axios from 'axios'

// ** Fetch Boards
export const fetchBoards = createAsyncThunk('appKanban/fetchBoards', async () => {
  const response = await axios.get(`${API_URL}/kanban`)

  return response.data
})

export const fetchTasks = createAsyncThunk('appKanban/fetchTasks', async () => {
  const response = await axios.get(`${API_URL}/kanban`)

  return response.data
})

export const updateTask = createAsyncThunk('appKanban/updateTask', async (data, { dispatch }) => {
  const response = await axios.patch(`${API_URL}/kanban`, { data : data, type: 'task' })
  // send json patch

  console.log(response)
  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response.data
})

export const addBoard = createAsyncThunk('appKanban/addBoard', async (data, { dispatch }) => {
  const response = await axios.post('/apps/kanban/add-board', { data })
  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response.data
})

export const addTask = createAsyncThunk('appKanban/addTask', async (data, { dispatch }) => {
  const response = await axios.post(`${API_URL}/kanban`, { data })
  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response.data
})

export const clearTasks = createAsyncThunk('appKanban/clearTasks', async (id, { dispatch }) => {
  const response = await axios.delete('/apps/kanban/clear-tasks', { data: id })

  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response
})

export const updateTaskBoard = createAsyncThunk('appKanban/updateTaskBoard', async (data, { dispatch }) => {
  const response = await axios.patch(`${API_URL}/kanban`, {
    data: data,
    type: 'board'
  } )
  // Send json patch
  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response.data
})

export const reorderTasks = createAsyncThunk('appKanban/reorder-tasks', async (data, { dispatch }) => {
  const response = await axios.post('/apps/kanban/reorder-tasks', { data })
  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response.data
})

export const deleteBoard = createAsyncThunk('appKanban/deleteBoard', async (id, { dispatch }) => {
  const response = await axios.delete('/apps/kanban/delete-board', { data: id })

  await dispatch(fetchBoards())
  await dispatch(fetchTasks())

  return response
})

export const kanban = createSlice({
  name: 'kanban',
  initialState: {
    tasks: [],
    boards: [
        {
            id: 'ideas',
            title: 'Ideas'
        },
        {
          id: 'todo',
          title: 'TODO'
        },
        {
            id: 'in-code',
            title: 'In Code'
          },
        {
          id: 'in-progress',
          title: 'In Progress'
        },
        {
          id: 'done',
          title: 'Done'
        }
      ],
    selectedTask: null
  },
  reducers: {
    handleSelectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder
      //.addCase(fetchBoards.fulfilled, (state, action) => {
      //  state.boards = action.payload
      //})
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.tasks
      })
  }
})

export const { handleSelectTask } = kanban.actions

export default kanban.reducer
