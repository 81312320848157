// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
// ** ThemeConfig Import
import { API_URL } from '@configs/endpoint'

export const getTags = createAsyncThunk("tags/getTags", async () => {
    const response = await axios.get(`${API_URL}/tags`)
    const data = await response.data.tags
    return data
})

export const tags = createSlice({
    name: "tags",
    initialState: {
        data: [],
        allData: [],
        selectedTag: {},
        isLoading: true,
        isError: false,
        total: 0,
        currentPage: 1,
        rowsPerPage: 10,
        searchText: "",
    },
    reducers: {
        startFetchTags: (state) => {
            state.isLoading = true
            state.isError = false
        },
        successFetchTags: (state, action) => {
            state.data = action.payload.slice(0, state.rowsPerPage)
            state.allData = action.payload
            state.total = action.payload.length
            state.isLoading = false
        },
        changePage: (state, action) => {
            state.currentPage = action.payload
            state.data = state.allData.slice(action.payload * state.rowsPerPage - state.rowsPerPage, action.payload * state.rowsPerPage)
        },
        changeRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
            state.data = state.allData.slice(0, action.payload)
            state.currentPage = 1
        },
        changeSearchTerm: (state, action) => {
            state.searchText = action.payload
            // if (action.payload !== ""){
            //     state.data = state.allData.filter(post => post.title.toLowerCase().includes(action.payload))
            //     state.currentPage = 1
            // }else{
            //     state.data = state.allData.slice(0, state.rowsPerPage)
            //     state.currentPage = 1
            // }
        },
        setSelectedTag: (state, action) => {
            state.selectedTag = action.payload
        },
        filterTags: (state, action) => {
            state.searchText = action.payload
            state.data = state.allData.filter(tag => tag.name.toLowerCase().includes(action.payload.toLowerCase()))
        }
    },
    extraReducers: builder => {
        builder.addCase(getTags.fulfilled, (state, action) => {
            state.data = action.payload
            state.allData = action.payload
            state.total = action.payload.length
            state.isLoading = false
        })
    }
})

export const {
    startFetchTags, successFetchTags, changePage, changeRowsPerPage,
    setSelectedTag, filterTags,
    changeSearchTerm
} = tags.actions

export default tags.reducer
